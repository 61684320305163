import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import cn from 'classnames'
import { Link } from 'gatsby'
import { useQueryParam, NumberParam } from 'use-query-params'

import { withMainLayout } from 'components/layouts'
import { PapersArchiveItemsGrid } from 'components/papers'
import { Pagination/*, SubscribeBlock*/ } from 'components/common'
import { SubscribeBlock } from 'components/subscribe'
import { fetchPapers } from 'components/papers/actions'
import css from './style.module.scss'
import SEO from 'components/seo'

function ArchivePage({
  fetchPapers,
}) {
  const [stateUrlPage, setUrlPage] = useQueryParam('page', NumberParam)
  const [stateCurrentPage, setCurrentPage] = useState(stateUrlPage || 1)
  const [statePapers, setPapers] = useState([])
  const [statePages, setPages] = useState(0)
  const [stateQuery, setQuery] = useState(`/newspaper/?page=${stateUrlPage || 1}`)

  useEffect(() => {
    fetchPapers(stateQuery)
      .then(result => {
        setCurrentPage(stateUrlPage || 1)
        setPapers(result.results)
        setPages(result.pages)
      })
  }, [stateQuery, /**/fetchPapers, /**/stateUrlPage])

  function handlePageChange(data) {
    setQuery(`/newspaper/?page=${data.selected + 1}`)
    setUrlPage(data.selected + 1)
  }

  return(
    <React.Fragment>
      <SEO
        title='Архив газеты “Шабат Шалом”'
      />
      <div className='container'>
        <div className={css.archivePage}>
          <div className={css.head}>
            <Link
              to='/'
              className={cn('linkBack caption primary-color', css.back)}
            >
              Вернуться на Главную
            </Link>
            <h2>
              Архив газеты “Шабат Шалом”
            </h2>
          </div>
          <div className={css.cards}>
            <PapersArchiveItemsGrid items={statePapers} />
          </div>
          {
            statePages > 1 &&
              <div className={css.pagination}>
                <Pagination
                  pageCount={statePages}
                  pageRangeDisplayed={3}
                  onPageChange={handlePageChange}
                  initialPage={stateCurrentPage - 1}
                />
              </div>
          }
        </div>
      </div>
      <SubscribeBlock />
    </React.Fragment>
  )
}

export default connect(
  null,
  dispatch => ({
    fetchPapers: bindActionCreators(fetchPapers, dispatch),
  })
)(withMainLayout(ArchivePage))