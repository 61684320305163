import React from 'react'

import SubscribeForm from './SubscribeFormContainer'
import css from './block.module.scss'

export default function SubscribeBlock() {
  return (
    <div className={css.subscribeBlock}>
      <div className='container'>
        <div className={css.subscribeForm}>
          <h3 align='center'>
            Подпишитесь на еженедельную рассылку
          </h3>
          <SubscribeForm />
        </div>
      </div>
    </div>
  )
}